<template>
    <form @submit="onsubmit" class="flex" id="textfield">
        <div v-for="(field, n) in fields" :key="n">
            <div :style="field.Style" :class="field.Class" v-if="field.Type === 'inputtext'">
                <input type="text" :name="field.Name" :placeholder="field.Placeholder" class="text-sm p-2 border-none w-full">
                <b-button class="rounded-none" v-if="field.Button" @click="onClick('textfield', field.Button.onClick)">{{ field.Button.Name }}</b-button>
            </div>
        </div>
    </form>
</template>
<script>
export default {
    props: {
        fields: {
            type: Array
        }
    },
    methods: {
        onClick (id, action) {
            var temp = { ...action }
            var data = this.getData(id)
            if (temp.Type === 'redirectUrl') {
                temp.Url = this.stringReader(temp.Url, data)
                this.mainAction(temp)
            }
        }
    }
}
</script>
